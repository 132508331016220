import layout1 from "@views/layouts/layout1";
import Wildlife from "@views/pages/wildlife";

import BestPractices from "@views/pages/best_practices";

import InnovationMudumalaiTigerReserve from "@views/pages/innovation_mudumalai_tiger_reserve";

import InnovationDharmapuri from "@views/pages/innovation_dharmapuri";

import Test1 from "@views/pages/test_1";

import Campa from "@views/pages/campa";

import ForestConservationAct from "@views/pages/forest_conservation_act";

import SharedSchemes from "@views/pages/shared_schemes";

import StateSchemes from "@views/pages/state_schemes";

import ExternallyAidedProject from "@views/pages/externally_aided_project";

import SocialForestry from "@views/pages/social_forestry";

import WildLifeIntroduction from "@views/pages/wild_life_introduction";

import RamsarSiteOfTn from "@views/pages/ramsar_site_of_tn";

import PartIiSchemes from "@views/pages/part_ii_schemes";

import GreenIndiaMission from "@views/pages/green_india_mission";

import NationalAfforestationProgramme from "@views/pages/national_afforestation_programme";

import ProtectionVigilancePV from "@views/pages/protection_vigilance_p_v";

import TnForestryTrainingCollege from "@views/pages/tn_forestry_training_college";

import SuccessStoryCoimbatore from "@views/pages/success_story_coimbatore";

import Extension from "@views/pages/extension";

import SacredGrovesOfTn from "@views/pages/sacred_groves_of_tn";

import ZooAuthorityOfTn from "@views/pages/zoo_authority_of_tn";

import TapPhaseIi from "@views/pages/tap_phase_ii";

import WorkingPlan from "@views/pages/working_plan";

import CoastalAreaManagement from "@views/pages/coastal_area_management";

import TreeCultivationInPrivateLandsTcpl from "@views/pages/tree_cultivation_in_private_lands_tcpl";

import HillAreaDevelopmentProgramme from "@views/pages/hill_area_development_programme";

import TankForeshorePlantations from "@views/pages/tank_foreshore_plantations";

import TapPhaseI from "@views/pages/tap_phase_i";

import Announcements from "@views/pages/announcements";

import WesternGhatsDevelopmentProgrammeWdgp from "@views/pages/western_ghats_development_programme_wdgp";

import SchemesImplementation from "@views/pages/schemes_implementation";

import PolicyNote from "@views/pages/policy_note";

import ThrustArea from "@views/pages/thrust_area";

import CitizenCharter from "@views/pages/citizen_charter";

import Strategies from "@views/pages/strategies";

import CentralSchemes from "@views/pages/central_schemes";

import PolicyInitiatives from "@views/pages/policy_initiatives";

import TribalDevelopment from "@views/pages/tribal_development";

import Codes from "@views/pages/codes";
import WildlifeCensus from "@views/pages/wildlife-census";

import Notifications from "@views/pages/notifications";

import Acts from "@views/pages/acts";

import ExternallyAidedProjectEapSchemes from "@views/pages/externally_aided_project_eap_schemes";

import TamilNaduTeaPlantationCorporationTanteaLimitedCoonoor from "@views/pages/tamil_nadu_tea_plantation_corporation_tantea_limited_coonoor";

import TamilNaduBiodiversityBoard from "@views/pages/tamil_nadu_biodiversity_board";

import InnovationMadurai from "@views/pages/innovation_madurai";

import InnovationStr from "@views/pages/innovation_str";

import InnovationTnfusrc from "@views/pages/innovation_tnfusrc";

import Test from "@views/pages/test";

import TamilnaduForestAcademy from "@views/pages/tamilnadu_forest_academy";

import Policies from "@views/pages/policies";

import OtherGeneralGOS from "@views/pages/other_general_g_o_s";

import Rules from "@views/pages/rules";

import ListOfCentralNurseriesInTheState from "@views/pages/list_of_central_nurseries_in_the_state";

import Guidelines from "@views/pages/guidelines";

import GuidelinesForSawmills from "@views/pages/guidelines_for_sawmills";

import EcoTourismSites from "@views/pages/eco_tourism_sites";

import InternationalPublications from "@views/pages/international_publications";

import GovtOfTamilNaduPublications from "@views/pages/govt_of_tamil_nadu_publications";

import General from "@views/pages/general";

import TamilNaduForestPlantationCorporationTafcornLimitedTrichy from "@views/pages/tamil_nadu_forest_plantation_corporation_tafcorn_limited_trichy";

import ArasuRubberCorporationArcLimitedNagercoil from "@views/pages/arasu_rubber_corporation_arc_limited_nagercoil";

import SuccessStorySrivilliputhur from "@views/pages/success_story_srivilliputhur";

import GOsForTanii from "@views/pages/g_os_for_tanii";

import GOSForOtherSchemes from "@views/pages/g_o_s_for_other_schemes";

import ListOfBiofertilizerCentres from "@views/pages/list_of_biofertilizer_centres";

import ManualsForOnlineSubmissions from "@views/pages/manuals_for_online_submissions";

import DepartmentalBooks from "@views/pages/departmental_books";

import FiveYearPlans from "@views/pages/five_year_plans";

import SaleOfSeedling from "@views/pages/sale_of_seedling";

import Research from "@views/pages/research";

import GovtOfIndiaPublications from "@views/pages/govt_of_india_publications";

import TnForestDeptPublications from "@views/pages/tn_forest_dept_publications";

import Recruitment from "@views/pages/recruitment";

import Biodiversity from "@views/pages/biodiversity";

import Mis from "@views/pages/mis";

import TamilNaduStateWetlandAuthority from "@views/pages/tamil_nadu_state_wetland_authority";

import ConservationAuthorityOfPallikaranai from "@views/pages/conservation_authority_of_pallikaranai";

import GulfOfMannarBiosphereReserveTrustGombrt from "@views/pages/gulf_of_mannar_biosphere_reserve_trust_gombrt";

import TamilNaduStateBiodiversityBoard from "@views/pages/tamil_nadu_state_biodiversity_board";

import GOsForWildlifeSchemes from "@views/pages/g_os_for_wildlife_schemes";

import SuccessStoryAtr from "@views/pages/success_story_atr";

import SuccessStoryTnfusrc from "@views/pages/success_story_tnfusrc";

import Tree_Felling_Permission from "@views/pages/Guidelines for obtaining tree felling and transit permission.jsx";
import TendersAdvertisements from "@views/pages/tenders_advertisements";
import Tree_Transit_Permission from "@views/pages/tree_transit_permission";

import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import home_design from "@views/pages/home_design";
import LatestNews from "@views/pages/latest_news.jsx";

import PaperClippings from "@views/pages/paper_clippings";

import Sanctuary from "@views/pages/Sanctuary/Sanctuary";
import AboutPastContent from "@views/pages/about_past";
import AboutPresentContent from "@views/pages/about_present_content";
import Administration from "@views/pages/administration/administration";
import BiodiversityTab from "@views/pages/biodiversity/biodiversity_tab/biodiversity_tab";
import BoardMission from "@views/pages/board_mission/board_mission";
import Calendar from "@views/pages/calender/calender";
import Circles from "@views/pages/circles";
import ContactUs from "@views/pages/contact_us/contact_us";
import DynamicList from "@views/pages/dynamic_list/dynamic_list_page";
import Edaf from "@views/pages/edaf/edaf";
import Faq from "@views/pages/faq";
import ForestReadingBooks from "@views/pages/forest_reading_books/forest_reading_books";
import geomatics_centre from "@views/pages/geomatics_centre";
import Albums from "@views/pages/home_design/components/media_&_gallery/albums/albums";
import SchemsAlbums from "@views/pages/home_design/components/media_&_gallery/albums/albums_schems";
import DynamicHTML from "@views/pages/html_dynamic_page/html_dynamic";
import Information_technology_it from "@views/pages/information_technology_it";
import ListOfIfsOfficers from "@views/pages/list_of_ifs_officers/list_of_ifs_officers";
import NationalParks from "@views/pages/national_parks/national_parks";
import OnlineService from "@views/pages/online_services";
import Pdar from "@views/pages/pdar/pdar";
import ProtectedArea from "@views/pages/protected_area/protected_area";
import RecentGOs from "@views/pages/recent_g_os";
import Rti from "@views/pages/rti";
import ScreenReader from "@views/pages/screen_reader/screen_reader";
import LoginScreen from "@views/pages/sign_in";
import SystemCmsValue from "@views/pages/system_cms_value/system_cms_value";
import Advertisements from "@views/pages/tenders_advertisements/advertisements";
import Tria from "@views/pages/tria/tria";
import Vision from "@views/pages/vision";
import WildlifeManagementPlan from "@views/pages/wildlife_management_plan/wildlife_management_plan";
import NewlyNotified from "@views/pages/newly_notified_rfs";

export const ROUTES = {
  LOGIN: "/login",
  ACTS: "/acts",
  HASH: "/#",
  CALENDAR: "/calendar-2024",
  ADMINISTRATION: "/administration",
  ANNOUNCEMENTS: "/announcements",
  ARASU_RUBBER_CORPORATION_ARC_LIMITED_NAGERCOIL:
    "/arasu-rubber-corporation-arc-limited-nagercoil",
  BEST_PRACTICES: "/best-practices",
  BIODIVERSITY: "/biodiversity",
  CAMPA: "/campa",
  CENTRAL_SCHEMES: "/central-schemes",
  CITIZEN_CHARTER: "/citizen-charter",
  COASTAL_AREA_MANAGEMENT: "/coastal-area-management",
  CODES: "/codes",
  WILDLIFECENSUS: "/wildlife-census",
  CONSERVATION_AUTHORITY_OF_PALLIKARANAI:
    "/conservation-authority-of-pallikaranai",
  DEPARTMENTAL_BOOKS: "/departmental-books",
  ECO_TOURISM_SITES: "/eco-tourism-sites",
  EMPTY: "/",
  EXTENSION: "/extension",
  EXTERNALLY_AIDED_PROJECT: "/externally-aided-project",
  EXTERNALLY_AIDED_PROJECT_EAP_SCHEMES: "/externally-aided-project-eap-schemes",
  FIVE_YEAR_PLANS: "/five-year-plans",
  FOREST_CONSERVATION_ACT: "/forest-conservation-act",
  G_O_S_FOR_OTHER_SCHEMES: "/g-o-s-for-other-schemes",
  G_OS_FOR_TANII: "/g-os-for-tanii",
  G_OS_FOR_WILDLIFE_SCHEMES: "/g-os-for-wildlife-schemes",
  GENERAL: "/general",
  GOVT_OF_INDIA_PUBLICATIONS: "/govt-of-india-publications",
  GOVT_OF_TAMIL_NADU_PUBLICATIONS: "/govt-of-tamil-nadu-publications",
  GREEN_INDIA_MISSION: "/green-india-mission",
  GUIDELINES: "/guidelines",
  GUIDELINES_FOR_SAWMILLS: "/guidelines-for-sawmills",
  GULF_OF_MANNAR_BIOSPHERE_RESERVE_TRUST_GOMBRT:
    "/gulf-of-mannar-biosphere-reserve-trust-gombrt",
  HILL_AREA_DEVELOPMENT_PROGRAMME: "/hill-area-development-programme",
  HOME: "/home",
  INNOVATION_DHARMAPURI: "/innovation-dharmapuri",
  INNOVATION_MADURAI: "/innovation-madurai",
  INNOVATION_MUDUMALAI_TIGER_RESERVE: "/innovation-mudumalai-tiger-reserve",
  INNOVATION_STR: "/innovation-str",
  INNOVATION_TNFUSRC: "/innovation-tnfusrc",
  INTERNATIONAL_PUBLICATIONS: "/international-publications",
  LIST_OF_BIOFERTILIZER_CENTRES: "/list-of-biofertilizer-centres",
  LIST_OF_CENTRAL_NURSERIES_IN_THE_STATE:
    "/list-of-central-nurseries-in-the-state",
  MANUALS_FOR_ONLINE_SUBMISSIONS: "/manuals-for-online-submissions",
  MIS: "/mis",
  NATIONAL_AFFORESTATION_PROGRAMME: "/national-afforestation-programme",
  NOTIFICATIONS: "/notifications",
  OTHER_GENERAL_G_O_S: "/other-general-g-o-s",
  PAPER_CLIPPINGS: "/paper-clippings",
  PART_II_SCHEMES: "/part-ii-schemes",
  POLICIES: "/policies",
  POLICY_INITIATIVES: "/policy-initiatives",
  POLICY_NOTE: "/policy-note",
  PROTECTION_VIGILANCE_P_V: "/protection-vigilance-p-v",
  RAMSAR_SITE_OF_TN: "/ramsar-site-of-tn",
  RECENT_G_OS: "/recent-gos",
  RECRUITMENT: "/recruitment",
  RESEARCH: "/research",
  RULES: "/rules",
  SACRED_GROVES_OF_TN: "/sacred-groves-of-tn",
  SALE_OF_SEEDLING: "/sale-of-seedling",
  SCHEMES_IMPLEMENTATION: "/schemes-implementation",
  SECONDARY_MENU: "/secondary-menu",
  SHARED_SCHEMES: "/shared-schemes",
  SOCIAL_FORESTRY: "/social-forestry",
  STATE_SCHEMES: "/state-schemes",
  STRATEGIES: "/strategies",
  SUCCESS_STORY_ATR: "/success-story-atr",
  SUCCESS_STORY_COIMBATORE: "/success-story-coimbatore",
  SUCCESS_STORY_SRIVILLIPUTHUR: "/success-story-srivilliputhur",
  SUCCESS_STORY_TNFUSRC: "/success-story-tnfusrc",
  TAMIL_NADU_BIODIVERSITY_BOARD: "/tamil-nadu-biodiversity-board",
  TAMIL_NADU_FOREST_PLANTATION_CORPORATION_TAFCORN_LIMITED_TRICHY:
    "/tamil-nadu-forest-plantation-corporation-tafcorn-limited-trichy",
  TAMIL_NADU_STATE_BIODIVERSITY_BOARD: "/tamil-nadu-state-biodiversity-board",
  TAMIL_NADU_STATE_WETLAND_AUTHORITY: "/tamil-nadu-state-wetland-authority",
  TAMIL_NADU_TEA_PLANTATION_CORPORATION_TANTEA_LIMITED_COONOOR:
    "/tamil-nadu-tea-plantation-corporation-tantea-limited-coonoor",
  TAMILNADU_FOREST_ACADEMY: "/tamilnadu-forest-academy",
  TANK_FORESHORE_PLANTATIONS: "/tank-foreshore-plantations",
  TAP_PHASE_I: "/tap-phase-i",
  TAP_PHASE_II: "/tap-phase-ii",
  TENDERS_ADVERTISEMENTS: "/tenders",
  NEWLY_NOTIFIED_RFS: "/newly-notified-rfs",
  TREE_FELLING_PERMISSION: "/tree_felling_permission",
  TREE_TRANSIT_PERMISSION: "/tree_transit_permission",
  ADVERTISEMENTS: "/advertisments",
  LATEST_NEWS: "/latest_news",
  TEST: "/test",
  TEST_1: "/test-1",
  THRUST_AREA: "/thrust-area",
  TN_FOREST_DEPT_PUBLICATIONS: "/tn-forest-dept-publications",
  TN_FORESTRY_TRAINING_COLLEGE: "/tn-forestry-training-college",
  TREE_CULTIVATION_IN_PRIVATE_LANDS_TCPL:
    "/tree-cultivation-in-private-lands-tcpl",
  TRIBAL_DEVELOPMENT: "/tribal-development",
  WESTERN_GHATS_DEVELOPMENT_PROGRAMME_WDGP:
    "/western-ghats-development-programme-wdgp",
  WILD_LIFE_INTRODUCTION: "/wild-life-introduction",
  WILDLIFE: "/wildlife",
  WORKING_PLAN: "/working-plan",
  ZOO_AUTHORITY_OF_TN: "/zoo-authority-of-tn",
  VISION: "/vision",
  BIODIVERSITY_TAB: "/biodiversity-tab",
  INFORMATION_TECHNOLOGY_IT: "/information_technology_it",
  GEOMATIC_CENTER: "/geomatics_centre",
  ONLINE_SERVICES: "/online-services",
  ABOUTPRESENTCONTENT: "/about_present_content",
  ABOUTPASTCONTENT: "/about_past",
  FAQ: "/faq",
  RTI: "/rti",
  LISTOFIFSOFFICERS: "/list_of_ifs_officers",
  CIRCLES: "/circles",
  CONTACTUS: "/contact_us",
  TRIA: "/tria",
  SANCTUARY: "/Sanctuary",
  NATIONAL_PARKS: "/national_parks",
  SCREEN_READER: "/screen_reader",
  ALBUMS: "/albums",
  SCHEMS_ALBUMS: "/schems_albums",
  BOARDMISSION: "/board_mission",
  PDAR: "/pdar",
  EDAF: "/edaf",
  PROTECTED_AREA: "/protected_area",
  DYNAMIC_LIST_PAGE: "/dynamic_list_page",
  DYNAMIC_HTML_PAGE: "/dynamic_html_page",
  SYSTEM_CMS_VALUE: "/system_cms_value",
  WILDLIFE_MANAGEMENT_PLAN: "/wildlife-management-plan",
  FOREST_READING_BOOKS: "/forest_reading_books",
};

let myRoutes = [
  {
    path: ROUTES.LOGIN,
    exact: true,
    page_key: "Login",
    component: LoginScreen,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SECONDARY_MENU,
    exact: true,
    page_key: "secondary-menu",
    component: SecondarySiderMenu,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.HOME,
    exact: true,
    page_key: "Home",
    component: home_design,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EMPTY,
    exact: true,
    page_key: "Home",
    component: home_design,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.HASH,
    exact: true,
    page_key: "Home",
    component: home_design,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WILDLIFE,
    exact: true,
    page_key: "wildlife",
    component: Wildlife,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CALENDAR,
    exact: true,
    page_key: "Calendar",
    component: Calendar,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BEST_PRACTICES,
    exact: true,
    page_key: "best-practices",
    component: BestPractices,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INNOVATION_MUDUMALAI_TIGER_RESERVE,
    exact: true,
    page_key: "innovation-mudumalai-tiger-reserve",
    component: InnovationMudumalaiTigerReserve,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INNOVATION_DHARMAPURI,
    exact: true,
    page_key: "innovation-dharmapuri",
    component: InnovationDharmapuri,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TEST_1,
    exact: true,
    page_key: "test-1",
    component: Test1,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CAMPA,
    exact: true,
    page_key: "campa",
    component: Campa,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FOREST_CONSERVATION_ACT,
    exact: true,
    page_key: "forest-conservation-act",
    component: ForestConservationAct,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SHARED_SCHEMES,
    exact: true,
    page_key: "shared-schemes",
    component: SharedSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STATE_SCHEMES,
    exact: true,
    page_key: "state-schemes",
    component: StateSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXTERNALLY_AIDED_PROJECT,
    exact: true,
    page_key: "externally-aided-project",
    component: ExternallyAidedProject,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SOCIAL_FORESTRY,
    exact: true,
    page_key: "social-forestry",
    component: SocialForestry,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WILD_LIFE_INTRODUCTION,
    exact: true,
    page_key: "wild-life-introduction",
    component: WildLifeIntroduction,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RAMSAR_SITE_OF_TN,
    exact: true,
    page_key: "ramsar-site-of-tn",
    component: RamsarSiteOfTn,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PART_II_SCHEMES,
    exact: true,
    page_key: "part-ii-schemes",
    component: PartIiSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GREEN_INDIA_MISSION,
    exact: true,
    page_key: "green-india-mission",
    component: GreenIndiaMission,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.NATIONAL_AFFORESTATION_PROGRAMME,
    exact: true,
    page_key: "national-afforestation-programme",
    component: NationalAfforestationProgramme,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PROTECTION_VIGILANCE_P_V,
    exact: true,
    page_key: "protection-vigilance-p-v",
    component: ProtectionVigilancePV,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TN_FORESTRY_TRAINING_COLLEGE,
    exact: true,
    page_key: "tn-forestry-training-college",
    component: TnForestryTrainingCollege,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SUCCESS_STORY_COIMBATORE,
    exact: true,
    page_key: "success-story-coimbatore",
    component: SuccessStoryCoimbatore,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXTENSION,
    exact: true,
    page_key: "extension",
    component: Extension,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SACRED_GROVES_OF_TN,
    exact: true,
    page_key: "sacred-groves-of-tn",
    component: SacredGrovesOfTn,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ZOO_AUTHORITY_OF_TN,
    exact: true,
    page_key: "zoo-authority-of-tn",
    component: ZooAuthorityOfTn,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAP_PHASE_II,
    exact: true,
    page_key: "tap-phase-ii",
    component: TapPhaseIi,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WORKING_PLAN,
    exact: true,
    page_key: "working-plan",
    component: WorkingPlan,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.COASTAL_AREA_MANAGEMENT,
    exact: true,
    page_key: "coastal-area-management",
    component: CoastalAreaManagement,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TREE_CULTIVATION_IN_PRIVATE_LANDS_TCPL,
    exact: true,
    page_key: "tree-cultivation-in-private-lands-tcpl",
    component: TreeCultivationInPrivateLandsTcpl,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.HILL_AREA_DEVELOPMENT_PROGRAMME,
    exact: true,
    page_key: "hill-area-development-programme",
    component: HillAreaDevelopmentProgramme,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TANK_FORESHORE_PLANTATIONS,
    exact: true,
    page_key: "tank-foreshore-plantations",
    component: TankForeshorePlantations,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAP_PHASE_I,
    exact: true,
    page_key: "tap-phase-i",
    component: TapPhaseI,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ANNOUNCEMENTS,
    exact: true,
    page_key: "announcements",
    component: Announcements,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WESTERN_GHATS_DEVELOPMENT_PROGRAMME_WDGP,
    exact: true,
    page_key: "western-ghats-development-programme-wdgp",
    component: WesternGhatsDevelopmentProgrammeWdgp,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SCHEMES_IMPLEMENTATION,
    exact: true,
    page_key: "schemes-implementation",
    component: SchemesImplementation,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.POLICY_NOTE,
    exact: true,
    page_key: "policy-note",
    component: PolicyNote,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.THRUST_AREA,
    exact: true,
    page_key: "thrust-area",
    component: ThrustArea,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CITIZEN_CHARTER,
    exact: true,
    page_key: "citizen-charter",
    component: CitizenCharter,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.STRATEGIES,
    exact: true,
    page_key: "strategies",
    component: Strategies,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CENTRAL_SCHEMES,
    exact: true,
    page_key: "central-schemes",
    component: CentralSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.POLICY_INITIATIVES,
    exact: true,
    page_key: "policy-initiatives",
    component: PolicyInitiatives,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TRIBAL_DEVELOPMENT,
    exact: true,
    page_key: "tribal-development",
    component: TribalDevelopment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CODES,
    exact: true,
    page_key: "codes",
    component: Codes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WILDLIFECENSUS,
    exact: true,
    page_key: "wildlife-census",
    component: WildlifeCensus,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.NOTIFICATIONS,
    exact: true,
    page_key: "notifications",
    component: Notifications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ACTS,
    exact: true,
    page_key: "acts",
    component: Acts,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EXTERNALLY_AIDED_PROJECT_EAP_SCHEMES,
    exact: true,
    page_key: "externally-aided-project-eap-schemes",
    component: ExternallyAidedProjectEapSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMIL_NADU_TEA_PLANTATION_CORPORATION_TANTEA_LIMITED_COONOOR,
    exact: true,
    page_key: "tamil-nadu-tea-plantation-corporation-tantea-limited-coonoor",
    component: TamilNaduTeaPlantationCorporationTanteaLimitedCoonoor,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMIL_NADU_BIODIVERSITY_BOARD,
    exact: true,
    page_key: "tamil-nadu-biodiversity-board",
    component: TamilNaduBiodiversityBoard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INNOVATION_MADURAI,
    exact: true,
    page_key: "innovation-madurai",
    component: InnovationMadurai,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INNOVATION_STR,
    exact: true,
    page_key: "innovation-str",
    component: InnovationStr,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INNOVATION_TNFUSRC,
    exact: true,
    page_key: "innovation-tnfusrc",
    component: InnovationTnfusrc,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TEST,
    exact: true,
    page_key: "test",
    component: Test,
    // layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMILNADU_FOREST_ACADEMY,
    exact: true,
    page_key: "tamilnadu-forest-academy",
    component: TamilnaduForestAcademy,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.POLICIES,
    exact: true,
    page_key: "policies",
    component: Policies,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.OTHER_GENERAL_G_O_S,
    exact: true,
    page_key: "other-general-g-o-s",
    component: OtherGeneralGOS,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RULES,
    exact: true,
    page_key: "rules",
    component: Rules,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LIST_OF_CENTRAL_NURSERIES_IN_THE_STATE,
    exact: true,
    page_key: "list-of-central-nurseries-in-the-state",
    component: ListOfCentralNurseriesInTheState,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GUIDELINES,
    exact: true,
    page_key: "guidelines",
    component: Guidelines,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GUIDELINES_FOR_SAWMILLS,
    exact: true,
    page_key: "guidelines-for-sawmills",
    component: GuidelinesForSawmills,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ECO_TOURISM_SITES,
    exact: true,
    page_key: "eco-tourism-sites",
    component: EcoTourismSites,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INTERNATIONAL_PUBLICATIONS,
    exact: true,
    page_key: "international-publications",
    component: InternationalPublications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GOVT_OF_TAMIL_NADU_PUBLICATIONS,
    exact: true,
    page_key: "govt-of-tamil-nadu-publications",
    component: GovtOfTamilNaduPublications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GENERAL,
    exact: true,
    page_key: "general",
    component: General,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMIL_NADU_FOREST_PLANTATION_CORPORATION_TAFCORN_LIMITED_TRICHY,
    exact: true,
    page_key: "tamil-nadu-forest-plantation-corporation-tafcorn-limited-trichy",
    component: TamilNaduForestPlantationCorporationTafcornLimitedTrichy,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ARASU_RUBBER_CORPORATION_ARC_LIMITED_NAGERCOIL,
    exact: true,
    page_key: "arasu-rubber-corporation-arc-limited-nagercoil",
    component: ArasuRubberCorporationArcLimitedNagercoil,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SUCCESS_STORY_SRIVILLIPUTHUR,
    exact: true,
    page_key: "success-story-srivilliputhur",
    component: SuccessStorySrivilliputhur,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.G_OS_FOR_TANII,
    exact: true,
    page_key: "g-os-for-tanii",
    component: GOsForTanii,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.G_O_S_FOR_OTHER_SCHEMES,
    exact: true,
    page_key: "g-o-s-for-other-schemes",
    component: GOSForOtherSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LIST_OF_BIOFERTILIZER_CENTRES,
    exact: true,
    page_key: "list-of-biofertilizer-centres",
    component: ListOfBiofertilizerCentres,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MANUALS_FOR_ONLINE_SUBMISSIONS,
    exact: true,
    page_key: "manuals-for-online-submissions",
    component: ManualsForOnlineSubmissions,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DEPARTMENTAL_BOOKS,
    exact: true,
    page_key: "departmental-books",
    component: DepartmentalBooks,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FIVE_YEAR_PLANS,
    exact: true,
    page_key: "five-year-plans",
    component: FiveYearPlans,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SALE_OF_SEEDLING,
    exact: true,
    page_key: "sale-of-seedling",
    component: SaleOfSeedling,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RESEARCH,
    exact: true,
    page_key: "research",
    component: Research,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GOVT_OF_INDIA_PUBLICATIONS,
    exact: true,
    page_key: "govt-of-india-publications",
    component: GovtOfIndiaPublications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TN_FOREST_DEPT_PUBLICATIONS,
    exact: true,
    page_key: "tn-forest-dept-publications",
    component: TnForestDeptPublications,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RECRUITMENT,
    exact: true,
    page_key: "recruitment",
    component: Recruitment,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BIODIVERSITY,
    exact: true,
    page_key: "biodiversity",
    component: Biodiversity,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.MIS,
    exact: true,
    page_key: "MIS",
    component: Mis,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMIL_NADU_STATE_WETLAND_AUTHORITY,
    exact: true,
    page_key: "tamil-nadu-state-wetland-authority",
    component: TamilNaduStateWetlandAuthority,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CONSERVATION_AUTHORITY_OF_PALLIKARANAI,
    exact: true,
    page_key: "conservation-authority-of-pallikaranai",
    component: ConservationAuthorityOfPallikaranai,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GULF_OF_MANNAR_BIOSPHERE_RESERVE_TRUST_GOMBRT,
    exact: true,
    page_key: "gulf-of-mannar-biosphere-reserve-trust-gombrt",
    component: GulfOfMannarBiosphereReserveTrustGombrt,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TAMIL_NADU_STATE_BIODIVERSITY_BOARD,
    exact: true,
    page_key: "tamil-nadu-state-biodiversity-board",
    component: TamilNaduStateBiodiversityBoard,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.G_OS_FOR_WILDLIFE_SCHEMES,
    exact: true,
    page_key: "g-os-for-wildlife-schemes",
    component: GOsForWildlifeSchemes,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SUCCESS_STORY_ATR,
    exact: true,
    page_key: "success-story-atr",
    component: SuccessStoryAtr,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SUCCESS_STORY_TNFUSRC,
    exact: true,
    page_key: "success-story-tnfusrc",
    component: SuccessStoryTnfusrc,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TENDERS_ADVERTISEMENTS,
    exact: true,
    page_key: "tenders",
    component: TendersAdvertisements,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TREE_FELLING_PERMISSION,
    exact: true,
    page_key: "Tree_Felling_Permission",
    component: Tree_Felling_Permission,
    layout: layout1,
    authenticate: false,
  },

  {
    path: ROUTES.TREE_TRANSIT_PERMISSION,
    exact: true,
    page_key: "Tree_Transit_Permission",
    component: Tree_Transit_Permission,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ADVERTISEMENTS,
    exact: true,
    page_key: "advertisements",
    component: Advertisements,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LATEST_NEWS,
    exact: true,
    page_key: "latest-news",
    component: LatestNews,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ADMINISTRATION,
    exact: true,
    page_key: "administration",
    component: Administration,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PAPER_CLIPPINGS,
    exact: true,
    page_key: "paper-clippings",
    component: PaperClippings,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RECENT_G_OS,
    exact: true,
    page_key: "recent-g-os",
    component: RecentGOs,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.VISION,
    exact: true,
    page_key: "vision",
    component: Vision,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BIODIVERSITY_TAB,
    exact: true,
    page_key: "vision",
    component: BiodiversityTab,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.INFORMATION_TECHNOLOGY_IT,
    exact: true,
    page_key: "information_technology_it",
    component: Information_technology_it,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.GEOMATIC_CENTER,
    exact: true,
    page_key: "geomatics_centre",
    component: geomatics_centre,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ONLINE_SERVICES,
    exact: true,
    page_key: "online_services",
    component: OnlineService,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.NEWLY_NOTIFIED_RFS,
    exact: true,
    page_key: "newly_notified_rfs",
    component: NewlyNotified,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ABOUTPRESENTCONTENT,
    exact: true,
    page_key: "about_present_content",
    component: AboutPresentContent,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ABOUTPASTCONTENT,
    exact: true,
    page_key: "about_past_content",
    component: AboutPastContent,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FAQ,
    exact: true,
    page_key: "faq",
    component: Faq,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.RTI,
    exact: true,
    page_key: "rti",
    component: Rti,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.LISTOFIFSOFFICERS,
    exact: true,
    page_key: "list_of_ifs_officers",
    component: ListOfIfsOfficers,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CIRCLES,
    exact: true,
    page_key: "circles",
    component: Circles,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.CONTACTUS,
    exact: true,
    page_key: "contact_us",
    component: ContactUs,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.TRIA,
    exact: true,
    page_key: "tria",
    component: Tria,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SANCTUARY,
    exact: true,
    page_key: "sanctuary",
    component: Sanctuary,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.NATIONAL_PARKS,
    exact: true,
    page_key: "national_parks",
    component: NationalParks,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SCREEN_READER,
    exact: true,
    page_key: "screen_reader",
    component: ScreenReader,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.ALBUMS,
    exact: true,
    page_key: "albums",
    component: Albums,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.SCHEMS_ALBUMS,
    exact: true,
    page_key: "schems_albums",
    component: SchemsAlbums,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.BOARDMISSION,
    exact: true,
    page_key: "board_mission",
    component: BoardMission,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.PDAR,
    exact: true,
    page_key: "pdar",
    component: Pdar,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.WILDLIFE_MANAGEMENT_PLAN,
    exact: true,
    page_key: "wildlife_management_plan",
    component: WildlifeManagementPlan,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.FOREST_READING_BOOKS,
    exact: true,
    page_key: "forest_reading_books",
    component: ForestReadingBooks,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.EDAF,
    exact: true,
    page_key: "edaf",
    component: Edaf,
    layout: layout1,
    authenticate: false,
    is_caps: true,
  },
  {
    path: `${ROUTES.PROTECTED_AREA}/:key`,
    exact: true,
    page_key: "protected_area",
    component: ProtectedArea,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DYNAMIC_LIST_PAGE,
    exact: true,
    page_key: "dynamic_list_page",
    component: DynamicList,
    layout: layout1,
    authenticate: false,
  },
  {
    path: ROUTES.DYNAMIC_HTML_PAGE,
    exact: true,
    page_key: "dynamic_html_page",
    component: DynamicHTML,
    layout: layout1,
    authenticate: false,
  },
  {
    path: `${ROUTES.SYSTEM_CMS_VALUE}/:id?`,
    exact: true,
    page_key: "system_cms_value",
    component: SystemCmsValue,
    // layout: AdminLayout,
    // layout: SystemCMS,
    layout: layout1,
    authenticate: false,
  },
];
export default myRoutes;
