import { BASE_URL_ASSETS } from "@helpers/constants";
import { ROUTES } from "@views/routes/my_routes";
import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Pressable,
  Stack,
  Text,
} from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OnlineServiceCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const moveToPage = (routes) => {
    history.push({
      pathname: `${routes}`,
    });
  };

  return (
    <>
      <HStack
        space="10"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        padding={["10px", "10px", "0px"]}
        justifyContent={"center"}
      >
        <Box
          maxW="400"
          rounded="lg"
          overflow="hidden"
          borderColor="coolGray.200"
          borderWidth="1"
          _dark={{
            borderColor: "coolGray.600",
            backgroundColor: "gray.700",
          }}
          _web={{
            shadow: 2,
            borderWidth: 0,
          }}
          _light={{
            backgroundColor: "gray.50",
          }}
        >
          <Box>
            <AspectRatio w="100%" ratio={16 / 9}>
              <Image
                source={{
                  uri: "https://indianmasterminds.com/wp-content/uploads/2021/07/20210718_125854-660x370.jpg",
                }}
                alt="image"
              />
            </AspectRatio>
          </Box>
          <Stack p="4" space={3} height={["500px", "500px", "840px"]}>
            <Stack space={2}>
              <Text
                fontSize="18px"
                fontWeight="bold"
                // textAlign={"center"}
                style={{
                  color: "green.500",
                }}
                color="rgb(17 114 47)"
                dark={{
                  color: "success.600",
                }}
              >
                ONLINE APPLICATIONS FOR
                <br /> TREE FELLING / TREE TRANSIT <br /> PERMITS
              </Text>

              <Text fontSize="xs" mt="4">
                <span>
                  <b>Tree felling</b>
                </span>

                <ul>
                  <li>
                    Felling and selling of sandalwood trees in private patta
                    lands
                  </li>
                  <li>
                    Permission / Intimation for felling of red sanders, black
                    wood, silver oak, rose wood and teak trees in private lands
                  </li>
                  <li>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="red"
                      onPress={() => {
                        moveToPage(ROUTES.TREE_FELLING_PERMISSION);
                      }}
                      fontSize={12}
                    >
                      <Text
                        fontSize="xs"
                        style={{
                          color: "blue",
                        }}
                        mt="1"
                      >
                        Guidelines For Obtaining Tree Felling Permission
                      </Text>
                    </Pressable>
                  </li>
                </ul>
              </Text>
              <HStack space="2" justifyContent="center" borderColor={"red.100"}>
                <a
                  href="https://tnforests.org.in/tree_felling_permit_app/login.aspx"
                  target={"_blank"}
                >
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    borderColor={"success.600"}
                    color="success.600"
                    colorScheme="success"
                  >
                    <HStack space="2" justifyContent="center">
                      <Box alignSelf={"center"}>
                        <BsBoxArrowUpRight />
                      </Box>
                      <Box alignSelf={"center"}>
                        <Text color="success.600">View Website</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
              <Text fontSize="xs" mt="3">
                <span>
                  <b>Tree transit</b>
                </span>

                <ul>
                  <li>
                    Property mark registration and issue of transit permits for
                    Non-exempt trees other than Red Sanders Trees / Timber
                  </li>
                  <li>
                    Property mark registration and issue of transit permits for
                    Red Sanders Trees/ Timber
                  </li>

                  <li>
                    <Pressable
                      _hover={{
                        color: "green.500",
                      }}
                      color="red"
                      onPress={() => {
                        moveToPage(ROUTES.TREE_TRANSIT_PERMISSION);
                      }}
                      fontSize={12}
                    >
                      <Text mt="1" style={{ color: "blue" }}>
                        Guidelines For Obtaining Tree Transit Permission
                      </Text>
                    </Pressable>
                  </li>
                </ul>
              </Text>
              <HStack space="2" justifyContent="center" borderColor={"red.100"}>
                <a href="https://ntps.nic.in/" target={"_blank"}>
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    borderColor={"success.600"}
                    color="success.600"
                    colorScheme="success"
                  >
                    <HStack space="2" justifyContent="center">
                      <Box alignSelf={"center"}>
                        <BsBoxArrowUpRight />
                      </Box>
                      <Box alignSelf={"center"}>
                        <Text color="success.600">View Website</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
              <Text fontSize="xs" mt="3">
                <span>
                  <b>Malaithalam</b>
                </span>
              </Text>
              <ul>
                <li style={{ listStyleType: "none" }}>
                  <Text fontSize="xs">
                    Malaithalam.com is dedicated to the Tamil Nadu Hill Areas
                    (Preservation of Trees) Act, 1955. This Act was enacted to
                    protect the hill regions of Tamil Nadu from deforestation
                    and soil erosion by regulating tree cutting and land
                    cultivation. Malaithalam.com provides resources such as tree
                    and log volume calculation methods, application forms for
                    tree-cutting permits, and tools to check vehicle permits and
                    application statuses.
                  </Text>
                </li>
              </ul>
              <HStack space="2" justifyContent="center" borderColor={"red.100"}>
                <a href="http://malaithalam.com/" target={"_blank"}>
                  <Button
                    variant={"outline"}
                    height="40px"
                    width="150px"
                    borderColor={"success.600"}
                    color="success.600"
                    colorScheme="success"
                  >
                    <HStack space="2" justifyContent="center">
                      <Box alignSelf={"center"}>
                        <BsBoxArrowUpRight />
                      </Box>
                      <Box alignSelf={"center"}>
                        <Text color="success.600">View Website</Text>
                      </Box>
                    </HStack>
                  </Button>
                </a>
              </HStack>
            </Stack>
          </Stack>
        </Box>
        <Box
          mt={["30px", "30px", "0px"]}
          maxW="400"
          rounded="lg"
          overflow="hidden"
          borderColor="coolGray.200"
          borderWidth="1"
          _dark={{
            borderColor: "coolGray.600",
            backgroundColor: "gray.700",
          }}
          _web={{
            shadow: 2,
            borderWidth: 0,
          }}
          _light={{
            backgroundColor: "gray.50",
          }}
        >
          <Box>
            <AspectRatio w="100%" ratio={16 / 9}>
              <Image
                source={{
                  // uri: "https://nt-tnfd-assets.s3.ap-south-1.amazonaws.com/treepedia.png",
                  uri: BASE_URL_ASSETS + "treepedia.png",
                }}
                width="400px"
                alt="image"
              />
            </AspectRatio>
          </Box>
          <Stack p="4" space={3} height={["360px", "360px", "320px"]}>
            <Stack space={2}>
              <Text
                fontSize="18px"
                fontWeight="bold"
                style={{
                  color: "green.500",
                }}
                color="rgb(17 114 47)"
                dark={{
                  color: "success.600",
                }}
              >
                TAMIL NADU TREEPEDIA
              </Text>
              <Text fontSize="xs" mt="10">
                TN Treepedia is an Android app developed by the TNFD under the
                Tamil Nadu Innovative Initiatives Scheme (TANI). This app
                focuses on making the choice of tree species for plantation
                easier. It provides a variety of options to farmers, home
                gardeners, industries, and individuals to choose the right tree
                species for their requirements. The main objective of this
                application is to guide them with the plantation technique and
                post-plantation care.
              </Text>
            </Stack>

            <HStack alignItems="center" space={4} justifyContent="center">
              <a
                href="https://play.google.com/store/search?q=treepedia&c=apps&hl=en"
                target={"_blank"}
              >
                <Button
                  variant={"outline"}
                  height="40px"
                  width="150px"
                  colorScheme="success"
                  borderColor={"success.600"}
                  color="success.600"
                >
                  <HStack space="2">
                    <Box alignSelf={"center"}>
                      <IoLogoGooglePlaystore />
                    </Box>
                    <Box>
                      <Text color="success.600">Downlaod App</Text>
                    </Box>
                  </HStack>
                </Button>
              </a>
              <a href="https://www.tntreepedia.com/" target={"_blank"}>
                <Button
                  variant={"outline"}
                  height="40px"
                  width="150px"
                  borderColor={"success.600"}
                  color="success.600"
                  colorScheme="success"
                >
                  <HStack space="2">
                    <Box alignSelf={"center"}>
                      <BsBoxArrowUpRight />
                    </Box>
                    <Box>
                      <Text color="success.600">View Website</Text>
                    </Box>
                  </HStack>
                </Button>
              </a>
            </HStack>
          </Stack>
        </Box>
      </HStack>
    </>
  );
};
export default OnlineServiceCard;
