import ForestImageCover from "@assets/images/menu/forest-at-glance.jpg";
import Butterfly from "@views/components/butterfly_animation";
import SecondarySiderMenu from "@views/components/modules/secondary_sider_menu";
import HeaderSection from "@views/components/ui/header_section";
import { Box, Hidden, HStack, VStack } from "native-base";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NewlyNotifiedRFsTable from "./components/newlynotified";
const NewlyNotified = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <Box>
      <Hidden only={["sm", "xs"]}>
        <Box zIndex={1000} position={"absolute"}>
          <Butterfly />
        </Box>
      </Hidden>
      <VStack>
        <Box>
          <HeaderSection
            imageUrl={ForestImageCover}
            title={"Newly Notified RFs"}
          />
        </Box>
        <Box my="10">
          <HStack
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Box w={["100%", "100%", "30%"]} h="100%">
              <VStack space={5} w="100%">
                <Box w="100%" alignItems="center">
                  <SecondarySiderMenu type="Newly_Notified_RFs" />
                </Box>
              </VStack>
            </Box>
            <Box w={["100%", "100%", "65%"]} h="100%">
              <NewlyNotifiedRFsTable />
            </Box>
          </HStack>
        </Box>
      </VStack>
    </Box>
  );
};
export default NewlyNotified;
